import { FC, useEffect } from 'react';
import classNames from 'classnames';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
// hooks
import useStartupsInfoData from 'src/cms/data/startups/useStartupsInfoData';
// animations
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';

import * as style from './Info.module.scss';

const Info: FC = () => {
  const { dataList } = useStartupsInfoData();

  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  return (
    <Section className={style.root}>
      {dataList.map((block, index) => (
        <div key={index} className={style.block}>
          <div className={style.content}>
            <Typography
              variant='h2Number'
              className={classNames(style.title, style.animatedText)}
            >
              {block.title}
            </Typography>
            <Typography variant='h5' className={style.animatedText}>
              {block.description}
            </Typography>
          </div>
        </div>
      ))}
    </Section>
  );
};

export default Info;
