// extracted by mini-css-extract-plugin
export var iconWrapper = "Tabs-module--iconWrapper--d0442";
export var linkActive = "Tabs-module--linkActive--6d55b";
export var linkNumber = "Tabs-module--linkNumber--2270a";
export var linkTitle = "Tabs-module--linkTitle--c759c";
export var linkWrapper = "Tabs-module--linkWrapper--0ae39";
export var pagination = "Tabs-module--pagination--467d4";
export var paginationDesktop = "Tabs-module--paginationDesktop--66d29";
export var paginationItem = "Tabs-module--paginationItem--7e764";
export var process = "Tabs-module--process--6765c";
export var step = "Tabs-module--step--0d9b8";
export var steps = "Tabs-module--steps--ff687";