import { FC, useCallback } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
// components
import Typography from 'src/components/Typography';

import * as style from './Service.module.scss';
import type { Props } from './Service.interface';

// Helpers
import { scrollToElement } from 'src/services/helpers';

const imageHeight = 120;

const Service: FC<Props> = ({ title, price, hours, features, image }) => {
  const scrollToContacts = useCallback(() => scrollToElement('contacts'), []);

  return (
    <div className={style.service}>
      <Typography variant='h3'>{title}</Typography>
      {image && (
        <div className={style.image}>
          <GatsbyImage
            alt='serviece'
            image={image.childImageSharp.gatsbyImageData}
            style={{
              height: imageHeight,
              width: imageHeight * image.childImageSharp.gatsbyImageData.width,
            }}
            objectFit='contain'
          />
        </div>
      )}
      <Typography variant='body'>Starts from</Typography>
      <Typography className={style.price} variant='body'>
        {price}
      </Typography>
      <Typography className={style.hours} variant='h5'>
        (ca.{hours} hours)
      </Typography>
      <div className={style.list}>
        {features.map((item, itemIndex) => (
          <Typography className={style.listItem} variant='h5' key={itemIndex}>
            {item}
          </Typography>
        ))}
      </div>
      <button className={style.hoverBlock} onClick={scrollToContacts}>
        Start a project
      </button>
    </div>
  );
};

export default Service;
