import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// hooks
import useStartupsIntroData from 'src/cms/data/startups/useStartupsIntroData';
import useStartupsMetaData from 'src/cms/data/startups/useStartupsMetaData';

// sections
import Info from './sections/Info';
import Included from './sections/Included';
import Process from './sections/Process';
import { PageProps } from 'gatsby';

const Startups: FC<PageProps> = ({ location }) => {
  // hooks
  const { title, mobileTitle, description } = useStartupsIntroData();
  const startupsMetaData = useStartupsMetaData();

  return (
    <PageLayout metaData={{ ...startupsMetaData, href: location.href }}>
      <Intro
        title={title}
        mobileTitle={mobileTitle}
        description={description}
        icon='forStartupsDrawing'
      />
      <Info />
      <Included />
      <Process />
    </PageLayout>
  );
};

export default Startups;
