import { useEffect } from 'react';
import classNames from 'classnames';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
import Tabs from 'src/components/Tabs';
// hooks
import useProcessData from 'src/cms/data/startups/useProcessData';
// animation
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';

import * as style from './Process.module.scss';

const Process = () => {
  // hooks
  const { title, description, stepList } = useProcessData();

  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  return (
    <Section title={title}>
      <div className={style.content}>
        <Typography
          className={classNames(style.description, style.animatedText)}
          variant='body'
        >
          {description}
        </Typography>
      </div>
      <Tabs list={stepList} />
    </Section>
  );
};

export default Process;
