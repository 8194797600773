// extracted by mini-css-extract-plugin
export var active = "Included-module--active--5cd73";
export var animatedText = "Included-module--animatedText--51a5c";
export var content = "Included-module--content--74b5d";
export var customDot = "Included-module--customDot--9f19e";
export var description = "Included-module--description--25ad1";
export var imageWrapper = "Included-module--imageWrapper--b9725";
export var pagination = "Included-module--pagination--bfa71";
export var paginationLink = "Included-module--paginationLink--89c4b";
export var services = "Included-module--services--087f1";
export var slider = "Included-module--slider--cf50d";