import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Slider, { Settings } from 'react-slick';
// components
import Typography from 'src/components/Typography/Typography';
import Icon from 'src/components/Icon/Icon';
// services
import { leadingZero } from 'src/services/helpers';
// hooks
import useWindowSize from 'src/hooks/useWindowSize';
// context
import CursorContext from 'src/contexts/CursorContext';
import SitePreloaderContext from 'src/contexts/SitePreloaderContext';
// animation
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { showFromOpacity } from 'src/animations/block';
import { drawSvg } from 'src/animations/svg';

import * as style from './Tabs.module.scss';
import type { Props } from './Tabs.interface';

const Tabs: FC<Props> = ({ list }) => {
  // hooks
  const { isMobile, isTablet } = useWindowSize();
  // context
  const { cursor } = useContext(CursorContext);
  const { isPageLoaded } = useContext(SitePreloaderContext);
  // state
  const [activeLink, setActiveLink] = useState<number>(0);
  // refs
  const sliderRef = useRef<Slider>(null);
  const sliderBlockRef = useRef<HTMLDivElement>(null);
  // memo values
  const sliderSettings = useMemo<Settings>(
    () => ({
      dots: false,
      arrows: false,
      accessibility: false,
      fade: true,
      beforeChange: (_: number, nextSlide: number) => {
        setActiveLink(nextSlide);
      },
    }),
    []
  );

  useEffect(() => {
    if (sliderBlockRef.current !== null) {
      const animation = showFromOpacity(sliderBlockRef.current, {
        duration: 3,
      }).pause();

      onHTMLElementEnterOnWindow(sliderBlockRef.current, () => {
        animation.play();
      });
    }
  }, []);

  useEffect(() => {
    list.forEach((item, index) => {
      const drawingElement = document.getElementById(`${item.icon}${index}`);

      if (drawingElement && isPageLoaded) {
        const animation = drawSvg(drawingElement).pause();

        onHTMLElementEnterOnWindow(drawingElement, () => {
          animation.play();
        });
      }
    });
  }, [list, isPageLoaded, activeLink]);

  const handleMouseEnter = () => {
    cursor?.enter();
  };

  const handleMouseLeave = () => {
    cursor?.leave();
  };

  const handleLinkClick = (index: number) => () => {
    if (sliderRef.current !== null) {
      sliderRef.current.slickGoTo(index);
    }
  };

  return (
    <div ref={sliderBlockRef}>
      {isMobile || isTablet ? (
        <div className={style.pagination}>
          {list[activeLink - 1] !== undefined ? (
            <div
              className={classNames(style.linkWrapper, style.paginationItem)}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleLinkClick(activeLink - 1)}
            >
              <Typography variant='body' className={style.linkNumber}>
                {leadingZero(activeLink)}
              </Typography>
              <Typography variant='body' className={style.linkTitle}>
                {list[activeLink - 1].title}
              </Typography>
            </div>
          ) : (
            <div />
          )}
          <div
            className={classNames(style.linkWrapper, style.paginationItem)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Typography
              variant='body'
              className={classNames(style.linkNumber, style.linkActive)}
            >
              {leadingZero(activeLink + 1)}
            </Typography>
            <Typography variant='body' className={style.linkTitle}>
              {list[activeLink].title}
            </Typography>
          </div>
          {list[activeLink + 1] !== undefined ? (
            <div
              className={classNames(style.linkWrapper, style.paginationItem)}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleLinkClick(activeLink + 1)}
            >
              <Typography variant='body' className={style.linkNumber}>
                {leadingZero(activeLink + 2)}
              </Typography>
              <Typography variant='body' className={style.linkTitle}>
                {list[activeLink + 1].title}
              </Typography>
            </div>
          ) : (
            <div />
          )}
        </div>
      ) : (
        <div className={classNames(style.pagination, style.paginationDesktop)}>
          {list.map((steps, index) => (
            <div
              className={classNames(style.linkWrapper, style.paginationItem)}
              key={index}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleLinkClick(index)}
            >
              <Typography
                variant='body'
                className={classNames(style.linkNumber, {
                  [style.linkActive]: activeLink === index,
                })}
              >
                {leadingZero(index + 1)}
              </Typography>
              <Typography variant='body' className={style.linkTitle}>
                {steps.title}
              </Typography>
            </div>
          ))}
        </div>
      )}
      <Slider ref={sliderRef} {...sliderSettings}>
        {list.map((steps, index) => (
          <div key={index}>
            <div className={style.process}>
              <div className={style.iconWrapper} id={`${steps.icon}${index}`}>
                <Icon name={steps.icon} />
              </div>
              <div className={style.steps}>
                {steps.steps.map((step, stepIndex) => (
                  <Typography
                    key={stepIndex}
                    className={style.step}
                    variant='body'
                  >
                    {step}
                  </Typography>
                ))}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Tabs;
