import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Slider, { Settings } from 'react-slick';
// components
import Section from 'src/components/Section';
import Icon from 'src/components/Icon';
import Typography from 'src/components/Typography';
import Service from 'src/components/Service';
// hooks
import useWindowSize from 'src/hooks/useWindowSize';
import useIncludedData from 'src/cms/data/startups/useIncludedData';
// animation
import { drawSvg } from 'src/animations/svg';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { appearanceOfText } from 'src/animations/text';
import { showFromOpacity } from 'src/animations/block';
// styles
import * as style from './Included.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Included: FC = () => {
  // hooks
  const { title, description, services } = useIncludedData();
  // state
  const [activeSlide, setActiveSlide] = useState<number>(0);
  // hooks
  const { isMobile } = useWindowSize();
  // refs
  const drawingImageRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Slider>(null);
  const servicesRef = useRef<HTMLDivElement>(null);

  // memo
  const sliderSettings = useMemo<Settings>(
    () => ({
      dots: true,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      adaptiveHeight: true,
      arrows: false,
      accessibility: false,
      swipeToSlide: true,
      beforeChange: (_: number, nextSlide: number) => {
        setActiveSlide(nextSlide);
      },
      customPaging: (i) => {
        return (
          <div
            className={classNames(style.customDot, {
              [style.active]: i === activeSlide,
            })}
          />
        );
      },
    }),
    [activeSlide]
  );

  useEffect(() => {
    if (drawingImageRef.current !== null) {
      const animation = drawSvg(drawingImageRef.current).pause();

      onHTMLElementEnterOnWindow(drawingImageRef.current, () =>
        animation.play()
      );
    }
  }, []);

  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  useEffect(() => {
    if (servicesRef.current !== null) {
      const animation = showFromOpacity(servicesRef.current, {
        duration: 3,
      }).pause();

      onHTMLElementEnterOnWindow(servicesRef.current, () => {
        animation.play();
      });
    }
  }, []);

  const handlePageClick = useCallback(
    (slideIndex: number) => () => {
      sliderRef.current?.slickGoTo(slideIndex);
    },
    []
  );

  return (
    <Section title={title}>
      <Section className={style.content}>
        <Typography
          variant='body'
          className={classNames(style.description, style.animatedText)}
        >
          {description}
        </Typography>
        <div ref={drawingImageRef} className={style.imageWrapper}>
          <Icon name='includedDrawing' />
        </div>
      </Section>
      <div ref={servicesRef}>
        {isMobile ? (
          <>
            <div className={style.pagination}>
              <div onClick={handlePageClick(activeSlide - 1)}>
                <Typography className={style.paginationLink} variant='h5'>
                  {services[activeSlide - 1]?.title}
                </Typography>
              </div>
              <Typography variant='h5'>
                {services[activeSlide].title}
              </Typography>
              <div onClick={handlePageClick(activeSlide + 1)}>
                <Typography className={style.paginationLink} variant='h5'>
                  {services[activeSlide + 1]?.title}
                </Typography>
              </div>
            </div>
            <Slider
              ref={sliderRef}
              {...sliderSettings}
              className={style.slider}
            >
              {services.map((service, index) => (
                <Service key={index} {...service} />
              ))}
            </Slider>
          </>
        ) : (
          <div className={style.services}>
            {services.map((service, index) => (
              <Service key={index} {...service} />
            ))}
          </div>
        )}
      </div>
    </Section>
  );
};

export default Included;
